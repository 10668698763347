'use strict';

const DownloadPage = {};

DownloadPage.init = function () {
    if (!document.body.classList.contains('download-page')) return;
    fbq('track', 'Lead')

    const pluginLinks = document.querySelectorAll('.plugin-link');

    Array.from(pluginLinks).forEach(pluginLink => {
        pluginLink.onclick = () => fbq('track', 'JetBrainsPluginLink')
    })

    window.onYouTubeIframeAPIReady = () => {
        new YT.Player('install-video', {
            videoId: 'AH90MI9H5dc',
            events: {
                'onStateChange': event => {
                    if (event.data === YT.PlayerState.PLAYING) {
                        fbq('track', 'AddToCart');
                    }
                }
            }
        });
    }
}

module.exports = DownloadPage;